import React,{useEffect} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LearnMore from "../components/learnmore"
import QuickBuild from "../components/saas/topContent"
import Instructions from "../components/saas/middleContent"
import SalesChannels from "../components/saas/bottomContent"
import ScrollReveal from 'scrollreveal'

const Saas = () => {
  useEffect(()=> {
    ScrollReveal().reveal('.saas-foo',
      {
        delay:0,
        distance: '80px',
        easing: 'ease-in-out',
        origin: "bottom",
        interval: 50,
        duration: 400,
        mobile: false,
        opacity : 0, 
        reset: false,
        init: false
      }
    )
  })
  return (
    <Layout pageInfo={{ pageName: "saas" }}>
      <SEO title="Saas Website" description="Use Shoprises to build your own platform. manage your own custmers, run your own brand, analyze your own traffic." path="saas"/>

      {/* 大图 */}
      {/* <div 
        className="saas"
      >
      <span className="saas-banner-icon1 saas-foo"></span>
      <span className="saas-banner-icon2 saas-foo"></span>
      <span className="saas-banner-icon3 saas-foo"></span>
      <span className="saas-banner-icon4 saas-foo"></span>
      <span className="saas-banner-icon5 saas-foo"></span>
      <span className="saas-banner-icon6 saas-foo"></span>
      <span className="saas-banner-icon7 saas-foo"></span>
      <span className="saas-foo">Saas Website</span>
      </div> */}
      {/* Shoprises quickly builds a website that belongs only to you. */}
      <QuickBuild />
      {/* All-round instructions, speed up the construction of the station */}
      <Instructions />
      {/* Omni-directional Sales Channels */}
      {/* <SalesChannels /> */}
      {/* 二级页脚 */}
      {/* <LearnMore /> */}
    </Layout>
  )

}

export default Saas

